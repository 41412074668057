<template>
  <div class="setting">
    <el-card header="系统设置">
      <el-form :model="form" :rules="rules" label-position="left" label-width="120px" v-loading="loading">
        <el-form-item label="slogan:">
          <el-input v-model="form.slogan"  />
        </el-form-item>
        <el-form-item label="首页新闻个数:">
          <el-input v-model="form.indexNewsCount" type="number" />
        </el-form-item>
        <el-form-item label="公司地址:">
          <el-input v-model="form.address" />
        </el-form-item>
        <el-form-item label="公司总机:">
          <el-input v-model="form.telephone" />
        </el-form-item>
        <el-form-item label="hr Email:">
          <el-input v-model="form.hrEmail" />
        </el-form-item>
        <el-form-item label="底部代码:">
          <el-input v-model="form.footer" type="textarea" :autosize="{ minRows: 5 }"></el-input>
          <p class="form-item-tip">备案号、统计代码、版权信息等</p>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="submitting" @click="submit">保存设置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { setting, settingSave } from '@/request/modules/setting'
import { Message } from 'element-ui'

export default {
  data () {
    return {
      form: {
        slogan: '',
        indexNewsCount: 5,
        address: '',
        telephone: '',
        hrEmail: '',
        footer: ''
      },
      rules: {
      },
      loading: false,
      submitting: false
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      setting({}).then(resp => {
        Object.keys(this.form).map(key => {
          this.form[key] = resp[key]
        })
        this.loading = false
      }).catch((message) => {
        Message.error(message)
        this.loading = false
      })
    },
    submit () {
      this.submitting = true
      settingSave(this.form).then(resp => {
        this.$message.success('保存成功!')
        this.submitting = false
      }).catch(() => {
        this.submitting = false
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
